const Palette = {
  main: {
    lightYellow: "#F5F3BB",
    lightBlue: "#90DDF0",
    darkBackground: "#080708",
    flashyPink: "#FF5964",
  },
};

export default Palette;
